<template>
  <div>
    <!--  start contract form view -->
    <b-card class="p-0" no-body>
      <!-- start project info -->
      <div
        style="background-color: rgba(50, 61, 76, 1)"
        class="invoice_header text-white invoice-padding m-0 p-0"
      >
        <!-- start project info -->
        <div class="invoice-padding mb-3 py-3 px-3">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <!-- Header: Left Content -->
            <div style="display: flex; align-items: center">
              <div class="logo-wrapper">
                <logo />
              </div>
            </div>

            <!-- Header: Right Content -->
            <div
              class="mt-2 text-right"
              v-html="
                'Clearspace Offices Inc.<br>901-20 Victoria Street<br>Toronto, ON, Canada M5C 2N8<br>(888) 502-5327'
              "
            />
          </div>
        </div>
      </div>
      <div class="px-3">
        <div class="w-100 mb-2 my-3">
          <h2
            style="
              font-style: normal !important;
              font-weight: 500 !important;
              font-size: 28px !important;
              color: #636363 !important;
            "
          >
          CHANGE ORDER (CO) 
            {{ changeOrder.change_order_number }}
          </h2>
        </div>
        <!-- start project info -->
        <b-row class="row_print">
          <b-col cols="12" lg="6" class="col_print">
            <div class="">
              <h5 class="change_order_section text-uppercase disclaimer">
                {{ $t("changeOrder.projectAddress", "en") }}
              </h5>
              <p class="address_proyect">
                {{ changeOrder.opportunity.address }} <br />
                {{ changeOrder.opportunity.geography }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="6" class="col_print">
            <h5 class="change_order_section text-uppercase disclaimer">
              {{ $t("changeOrder.requestedDate", "en") }}
            </h5>
            <p>{{ changeOrder.request_date }}</p>
          </b-col>
          <b-col cols="12" lg="6" class="col_print">
            <div class="">
              <h5 class="change_order_section text-uppercase disclaimer">
                {{ $t("changeOrder.requestedBy", "en") }}
              </h5>
              <p class="address_proyect">
                {{ changeOrder.requesting_party_name }}
                {{
                  changeOrder.requesting_party_company != null &&
                  changeOrder.requesting_party_name != null
                    ? ","
                    : ""
                }}
                {{ changeOrder.requesting_party_company }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="6" class="col_print">
            <h5 class="change_order_section text-uppercase disclaimer">
              {{ $t("changeOrder.issueDateLabel", "en") }}
            </h5>
            <p>{{ changeOrder.issue_date }}</p>
          </b-col>
          <b-col cols="12" lg="6" class="col_print">
            <div class="">
              <h5 class="change_order_section text-uppercase disclaimer">
                {{ $t("changeOrder.ownerClient", "en") }}
              </h5>
              <p class="address_proyect">
                {{ changeOrder.owner_name }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="6" class="col_print">
            <h5 class="change_order_section text-uppercase disclaimer">
              {{ $t("changeOrder.expiryDate", "en") }}
            </h5>
            <p>{{ changeOrder.expiry_date }}</p>
          </b-col>
        </b-row>
        <!-- end project info -->
      </div>
      <!-- end project info -->
      <!-- *divider -->
      <hr />
      <!-- start change details -->
      <div class="my-3 mtp-0 px-3">
        <div class="my-2">
          <h5 class="change_order_section_overview bold_text">
            {{ $t("changeOrder.changeOrderOverview", "en") }}
          </h5>
        </div>
        <div class="my-2">
          <p class="d-flex" v-if="changeOrder.type">
            {{ $t("changeOrder.reasonForChange", "en") }} &nbsp;
            <b>
              <p>{{ reasonChangeLang("en", changeOrder.type) }}</p>
            </b>
          </p>
          <p>
            {{ changeOrder.overview }}
          </p>
        </div>
      </div>
      <!-- end change details -->
      <!-- start supporting -->
      <div
        v-if="
          changeOrder.supporting_documents != '' &&
          changeOrder.supporting_documents != ' ' &&
          changeOrder.supporting_documents != null
        "
        class="my-3 mtp-0 px-3"
      >
        <div class="my-2">
          <h5 class="change_order_section_overview bold_text">
            {{ $t("changeOrder.supportingDocuments", "en") }}
          </h5>
        </div>
        <div class="my-2 mtp-0">
          <p style="white-space: pre-line; line-break: anywhere">
            {{ changeOrder.supporting_documents }}
          </p>
        </div>
      </div>
      <!-- end supporting -->
      <!-- start price summary -->
      <div class="my-3 mtp-0 px-3">
        <b-row class="row_print">
          <b-col cols="12" lg="6" class="col_print">
            <b-card class="pl-2 pr-3" bg-variant="light">
              <b class="change_order_section_overview_summary bold_text">{{
                $t("changeOrder.contractPriceSummary", "en")
              }}</b>
              <b-row class="my-2 mr-2">
                <b-col
                  cols="8"
                  v-if="changeOrder.original_contract_price != 0"
                  class="contract_summary disclaimer mb-1"
                  >{{ $t("changeOrder.originalContractPrice", "en") }}</b-col
                >
                <b-col
                  cols="4"
                  v-if="changeOrder.original_contract_price != 0"
                  class="text-end"
                  >{{ numberFormatter(changeOrder.original_contract_price) }}</b-col
                >
                <b-col
                  cols="8"
                  v-if="netChangePreviousCO != 0"
                  class="contract_summary disclaimer mb-1"
                  >{{ $t("changeOrder.netChangeOfApprovedCos", "en") }}</b-col
                >
                <b-col cols="4" v-if="netChangePreviousCO != 0" class="text-end"
                  >{{ numberFormatter(netChangePreviousCO) }}
                </b-col>
                <b-col cols="8" class="contract_summary disclaimer"
                  >{{ $t("changeOrder.netChangeOfThisCO", "en") }}
                </b-col>
                <b-col cols="4" class="text-end">{{
                  numberFormatter(netChangeOrderTotal())
                }}</b-col>
              </b-row>
              <hr />
              <b-row class="mr-2">
                <b-col cols="7" class="contract_summary disclaimer">{{
                  $t("changeOrder.newTotalContractPrice", "en")
                }}</b-col>
                <b-col cols="5" class="text-primary text-end"
                  ><b v-if="!isNaN(netChangePreviousCO)">
                    {{
                      numberFormatter(
                        changeOrder.original_contract_price +
                          netChangePreviousCO +
                          netChangeOrderTotal()
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                      })
                    }}</b
                  >
                  <b v-else>
                    {{
                      numberFormatter(
                        changeOrder.original_contract_price + netChangeOrderTotal()
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                      })
                    }}</b
                  >
                </b-col></b-row
              >
            </b-card>
          </b-col>
          <b-col cols="12" lg="6" class="col_print">
            <b-card bg-variant="light">
              <b class="change_order_section_overview_summary bold_text">{{
                $t("changeOrder.changeInSchedule", "en")
              }}</b>
              <b-row class="my-2 mr-2">
                <b-col cols="8" class="contract_summary disclaimer mb-1">{{
                  $t("changeOrder.currentCompletionDate", "en")
                }}</b-col>
                <b-col cols="4" class="text-end">{{
                  changeOrder.current_substantial_completion
                }}</b-col>
                <b-col cols="8" class="contract_summary disclaimer">{{
                  $t("changeOrder.impactToSchedule", "en")
                }}</b-col>
                <b-col cols="4" class="text-end">{{
                  changeOrder.impact_to_schedule_business_days
                }}</b-col>
              </b-row>
              <hr />
              <b-row class="mr-2">
                <b-col cols="8" class="contract_summary disclaimer">{{
                  $t("changeOrder.newCompletionDate", "en")
                }}</b-col>
                <b-col cols="4" class="text-primary text-end"
                  ><b> {{ changeOrder.new_substantial_completion_date }}</b></b-col
                ></b-row
              >
            </b-card>
          </b-col>
        </b-row>
      </div>
      <!-- end price summary-->
      <!-- star execution -->
      <div class="px-3">
        <div class="my-2 mtp-0">
          <h5 class="change_order_section_overview bold_text mtp-0">
            {{ $t("changeOrder.execution", "en") }}
          </h5>
        </div>
        <div class="my-2">
          <div>
            <b-row class="row_print">
              <b-col cols="12" lg="6" class="col_print">
                <div class="pb-12 mt-5 pt-2 mtp-0">
                  <hr />
                  <p class="change_order_section disclaimer">
                    {{ $t("changeOrder.approvalBy", "en") }}
                  </p>
                  <p>
                    {{ changeOrder.approving_party_name }}
                    <br />
                    {{ changeOrder.approving_party_title }}
                    <br />
                    {{ changeOrder.approving_party_company }}
                  </p>
                </div>
              </b-col>
              <b-col cols="12" lg="6" class="col_print">
                <div class="pb-12 mt-5 pt-2 mtp-0">
                  <hr />
                  <p class="change_order_section disclaimer">
                    {{ $t("changeOrder.acceptedBy", "en") }}
                  </p>
                  <p class="signature_name">
                    {{ changeOrder.clearspace_project_manager }}
                    <br />
                    Clearspace Offices Inc.
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <!-- end execution -->
      <!-- start notes -->
      <div class="mt-5 pt-5 mtp-0 px-3">
        <hr class="mb-2" />
        <p class="notesLabel">
          {{ $t("changeOrder.notesLabel", "en") }}
        </p>
        <p class="notes" v-html="$t('changeOrder.contractNotes', 'en')" />
        <p class="text-end confidential-line change_order_section notprintable">
          {{ $t("changeOrder.confidential", "en") }}
        </p>

        <div class="page_botton_margin"></div>
      </div>
      <!-- end notes  -->
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
    </b-card>
    <!-- end contract form view -->
  </div>
</template>

<script>
import { BCard, BCol, BRow } from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
export default {
  props: {
    changeorder: Object,
  },
  components: {
    BCard,
    BCol,
    BRow,
    Logo,
  },
  data() {
    return {
      changeOrder: this.changeorder,
    };
  },
  computed: {
    netChangePreviousCO() {
      return this.changeOrder.opportunity.net_approved_change_orders;
    },
  },
  methods: {
    numberFormatter(number) {
      return (
        (number < 0 ? "-" : "") +
        "$" +
        Number(Math.abs(number.toFixed(2))).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        })
      );
    },
    netChangeOrderTotal() {
      return this.changeOrder.admin_fee_enabled
        ? this.changeOrder.admin_fee_subtotal + this.changeOrder.subtotal
        : this.changeOrder.subtotal;
    },
    reasonChangeLang(lang, reason) {
      if (lang == "en") {
        return reason;
      } else {
        if (reason == "Client Directed Change") return "À la demande du client";
        if (reason == "Unforeseen Site Condition")
          return "Condition de chantier imprévue";
        if (reason == "Design/Eng Error + Omission")
          return "Erreur ou omission - Conception plans";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.change_order_section {
  color: #b9b9c3;
  text-transform: uppercase;
  &_overview {
    color: #636363;
    &_summary {
      font-size: 18px;
    }
  }
}
.contract_summary {
  color: #b9b9c3;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.confidential-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
  text-transform: uppercase;
}
.text-end {
  justify-content: end;
  display: flex;
}
@media print {
  .page_botton_margin {
    position: fixed;
    bottom: 0px !important;
    border-bottom: rgba(92, 177, 231, 1) solid 8px !important;
    width: 140%;
    padding: 20px;
    margin-left: -80px;
  }
  @page {
    margin: 10mm 0 0mm 0;
  }
  .row_print {
    display: flex;
  }
  .col_print {
    flex: 50%;
  }
  .mtp-0 {
    margin: 0 !important;
  }
  .notprintable {
    display: none;
  }
  .disclaimer {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #b9b9c3;
  }
}
</style>
